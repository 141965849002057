import React, { useEffect } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Tooltip,
  Divider,
  Textarea,
  Card,
  CardBody,
  CardHeader,
  Switch,
} from "@nextui-org/react";
import { cn, formatDate, getTimeFromNowInWords } from "@/lib/utils";
import { Ellipsis, Info, Plus } from "lucide-react";
import { toast } from "sonner";
import { useRecoilState } from "recoil";
import GlobalAtoms from "@/lib/GlobalStore";
import { ApiCalls } from "@/lib/ApiCall";
import {
  CreateApiKeyRequestType,
  CreateApiKeyResponseType,
} from "@/types/api-key";
import { ApiKeyScopeType, ApiKeyType } from "@/types/schema";

export type ApiKeyManagerProps = {
  title: string;
  description: string;
  accentColor?: string;
  textColor?: string;
  darkMode?: boolean;
};

const ApiKeyManager = (props: ApiKeyManagerProps) => {
  const { title, description, accentColor, textColor } = props;

  const [showCreateKeyModal, setShowCreateKeyModal] = React.useState(false);
  const [showEditKeyModal, setShowEditKeyModal] = React.useState(false);

  const [showNewKeyDetailsModal, setShowNewKeyDetailsModal] =
    React.useState(false);
  const [showRotateKeyModal, setShowRotateKeyModal] = React.useState(false);
  const [showDeleteKeyModal, setShowDeleteKeyModal] = React.useState(false);

  const [apiKeyToRotate, setApiKeyToRotate] = React.useState<ApiKeyType | null>(
    null
  );

  const [apiKeyToDelete, setApiKeyToDelete] = React.useState<ApiKeyType | null>(
    null
  );

  const [apiKeyToEdit, setApiKeyToEdit] = React.useState<ApiKeyType | null>(
    null
  );

  const popupMenuTriggerDivRef = React.useRef<HTMLDivElement>(null);

  const [keyNotesInputValue, setKeyNotesInputValue] = React.useState("");
  const [newlyCreatedApiKey, setNewlyCreatedApiKey] =
    React.useState<CreateApiKeyResponseType | null>(null);
  const allScopes = useRecoilState(GlobalAtoms.AllScopesAtom)[0];
  const currentWorkspace =
    useRecoilState(GlobalAtoms.SelectedWorkspaceAtom)[0]?.id ?? "";

  const [newApiKeyData, setNewApiKeyData] =
    React.useState<CreateApiKeyRequestType>({
      name: "",
      workspaceId: currentWorkspace ?? "",
      customerId: "",
      scopes: [],
      enabled: true,
      totalUsageAllowed: undefined,
    });

  const allApiKeys = useRecoilState(GlobalAtoms.AllApiKeysAtom)[0].filter(
    (i) => !i.deleted
  );

  useEffect(() => {
    const getData = async () => {
      if (!currentWorkspace) return;
      await ApiCalls.getAllApiKeys(currentWorkspace);
      await ApiCalls.getAllScopes(currentWorkspace);
    };

    if (currentWorkspace) {
      setNewApiKeyData({
        name: "",
        workspaceId: currentWorkspace,
        customerId: "",
        scopes: [],
        enabled: true,
        totalUsageAllowed: undefined,
      });
    }
    getData();
  }, [currentWorkspace]);

  const refreshApiKeys = async () => {
    setNewlyCreatedApiKey(null);
    setNewApiKeyData({
      name: "",
      workspaceId: currentWorkspace ?? "",
      customerId: "",
      scopes: [],
      enabled: true,
      totalUsageAllowed: undefined,
    });
    setKeyNotesInputValue("");
    if (!currentWorkspace) return;
    await ApiCalls.getAllApiKeys(currentWorkspace);
  };

  const handleCreateApiKey = async () => {
    const response = await ApiCalls.createApiKey({
      ...newApiKeyData,
      totalUsageAllowed:
        newApiKeyData.totalUsageAllowed !== undefined &&
        newApiKeyData.totalUsageAllowed > 0
          ? newApiKeyData.totalUsageAllowed
          : undefined,
    });
    setNewlyCreatedApiKey(response);
  };

  const handleEditApiKey = async () => {
    if (!currentWorkspace || !apiKeyToEdit) return;
    await ApiCalls.editApiKey(currentWorkspace, apiKeyToEdit.id, {
      name: apiKeyToEdit.name,
      scopes: apiKeyToEdit.scopes,
      enabled: apiKeyToEdit.enabled,
    });
    await ApiCalls.getAllApiKeys(currentWorkspace);
    setApiKeyToEdit(null);
  };

  const handleApiKeyRotation = async () => {
    if (!currentWorkspace || !apiKeyToRotate) return;
    const response = await ApiCalls.rotateApiKey(
      currentWorkspace,
      apiKeyToRotate.id
    );
    setNewlyCreatedApiKey(response);
    setShowNewKeyDetailsModal(true);
    setApiKeyToRotate(null);
  };

  const changeScopePermission = (
    scopes: ApiKeyScopeType[],
    scopeRepresentation: string,
    permission: "none" | "read" | "write"
  ): ApiKeyScopeType[] => {
    let foundScope = false;
    const foundId = apiKeyToEdit?.scopes.find(
      (s) => s.representation === scopeRepresentation
    )?.id;

    const newScopes = scopes.map((scope) => {
      if (scope.representation === scopeRepresentation) {
        foundScope = true;
        return {
          id: foundId,
          representation: scope.representation,
          read: permission === "none" ? false : true,
          create: permission === "write" ? true : false,
          update: permission === "write" ? true : false,
          del: permission === "write" ? true : false,
        };
      }
      return scope;
    });

    if (!foundScope) {
      newScopes.push({
        id: foundId,
        representation: scopeRepresentation,
        read: permission === "none" ? false : true,
        create: permission === "write" ? true : false,
        update: permission === "write" ? true : false,
        del: permission === "write" ? true : false,
      });
    }

    return newScopes;
  };

  const handleDeleteApiKey = async () => {
    if (!currentWorkspace || !apiKeyToDelete) return;
    const data = await ApiCalls.deleteApiKey(
      currentWorkspace,
      apiKeyToDelete.id
    );
    if (data.apiKey.deleted) {
      toast("Key deleted successfully", {
        duration: 2000,
      });
      await ApiCalls.getAllApiKeys(currentWorkspace);
    }
    setApiKeyToDelete(null);
  };

  return (
    <div className=" text-sm max-w-[800px]">
      <Card
        className={cn(
          props.darkMode ? "bg-zinc-900 text-white" : "bg-white",
          "p-4"
        )}
      >
        <CardBody>
          <div className="flex justify-between gap-20 mb-8">
            <div className="flex-1 flex flex-col gap-2">
              <div className=" text-lg font-semibold">{title}</div>
              <div className=" text-tiny font-medium">{description}</div>
            </div>
            <div>
              <Button
                size="sm"
                className=" font-semibold"
                style={{
                  backgroundColor: accentColor,
                  color: textColor,
                }}
                onClick={() => setShowCreateKeyModal(true)}
              >
                <Plus size={14} /> Create secret key
              </Button>
            </div>
          </div>
          <div>
            <Table
              aria-label="Example static collection table"
              className={cn(props.darkMode ? "dark" : "")}
            >
              <TableHeader>
                <TableColumn>Name</TableColumn>
                <TableColumn>Token</TableColumn>
                <TableColumn>Last Used</TableColumn>
                <TableColumn>Created</TableColumn>
                <TableColumn> </TableColumn>
              </TableHeader>
              <TableBody>
                {allApiKeys.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell className="text-xs">
                      <div className="flex items-center gap-2">
                        {data.name}
                        {/* {data.scopes && (
                            <Tooltip
                              content={data.scopes
                                .map((scope) => scope.representation)
                                .join(", ")}
                              placement="right"
                              size="sm"
                              className="max-w-40"
                            >
                              <NotepadText
                                className="cursor-pointer"
                                size={14}
                              />
                            </Tooltip>
                          )} */}
                      </div>
                    </TableCell>
                    <TableCell className="text-xs">{data.maskedKey}</TableCell>
                    <TableCell className="text-xs">
                      {data.lastUsedAt
                        ? getTimeFromNowInWords(data.lastUsedAt)
                        : "Never"}
                    </TableCell>
                    <TableCell className="text-xs">
                      {formatDate(data.createdAt)}
                    </TableCell>
                    <TableCell>
                      <Popover
                        placement="bottom-end"
                        showArrow
                        shouldCloseOnBlur
                        backdrop="opaque"
                      >
                        <PopoverTrigger>
                          <div
                            ref={popupMenuTriggerDivRef}
                            className="flex justify-center max-w-8 cursor-pointer border-1 border-transparent hover:border-slate-300 hover:rounded-sm hover:shadow-sm"
                          >
                            <Ellipsis size={16} />
                          </div>
                        </PopoverTrigger>
                        <PopoverContent
                          className={cn(
                            props.darkMode ? "bg-slate-800 text-white" : "",
                            " rounded-sm "
                          )}
                        >
                          <div className="flex flex-col gap-4 w-44 py-2 text-xs font-medium">
                            <div
                              className=" cursor-pointer hover:font-semibold"
                              onClick={() => {
                                popupMenuTriggerDivRef.current?.click();
                                setApiKeyToRotate(data);
                                setShowRotateKeyModal(true);
                              }}
                            >
                              Rotate Key...
                            </div>
                            <div
                              className="cursor-pointer hover:font-semibold"
                              onClick={() => {
                                popupMenuTriggerDivRef.current?.click();
                                setApiKeyToEdit(data);
                                setShowEditKeyModal(true);
                              }}
                            >
                              Edit Key...
                            </div>
                            {/* <div className="cursor-pointer hover:font-semibold">
                                Manage IP Restrictions...
                              </div>
                              <div className="cursor-pointer hover:font-semibold">
                                View Request Logs
                              </div> */}
                            <div
                              className={cn(
                                props.darkMode
                                  ? "text-danger-400"
                                  : "text-danger-600",
                                " cursor-pointer hover:font-semibold"
                              )}
                              onClick={() => {
                                popupMenuTriggerDivRef.current?.click();
                                setApiKeyToDelete(data);
                                setShowDeleteKeyModal(true);
                              }}
                            >
                              Delete Key
                            </div>
                          </div>
                        </PopoverContent>
                      </Popover>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardBody>
      </Card>

      <Modal
        isOpen={showCreateKeyModal}
        onOpenChange={(isOpen) => {
          setShowCreateKeyModal(isOpen);
        }}
        size="lg"
        className={cn(
          props.darkMode ? "dark bg-zinc-900 text-white" : "",
          "p-4"
        )}
      >
        <ModalContent className=" max-h-full overflow-scroll">
          {(onClose) => (
            <div>
              <ModalHeader className="flex flex-col gap-1">
                Create a new secret key
              </ModalHeader>
              <ModalBody>
                <span className="text-sm font-medium">Customer ID</span>
                <Input
                  size="sm"
                  className="w-full rounded-sm"
                  value={newApiKeyData.customerId}
                  onValueChange={(e) => {
                    setNewApiKeyData({
                      ...newApiKeyData,
                      customerId: e,
                    });
                  }}
                  placeholder="Enter customer id for this key"
                />
                <div className="pt-4 flex justify-between items-center text-sm font-medium">
                  Set Usage Limit
                  <div className="flex gap-2">
                    {newApiKeyData.totalUsageAllowed !== undefined ? (
                      <Input
                        size="sm"
                        className="max-w-20"
                        value={String(newApiKeyData.totalUsageAllowed ?? 100)}
                        onValueChange={(e) => {
                          setNewApiKeyData({
                            ...newApiKeyData,
                            totalUsageAllowed: Number(e),
                          });
                        }}
                        type="number"
                      />
                    ) : null}
                    <Switch
                      size="sm"
                      color="success"
                      isSelected={
                        newApiKeyData.totalUsageAllowed !== undefined
                          ? true
                          : false
                      }
                      onValueChange={(e) => {
                        setNewApiKeyData({
                          ...newApiKeyData,
                          totalUsageAllowed: e ? 100 : undefined,
                        });
                      }}
                    />
                  </div>
                </div>
                <span className="pt-4 text-sm font-medium">Key name</span>
                <Input
                  size="sm"
                  className="w-full rounded-sm"
                  value={newApiKeyData.name}
                  onValueChange={(e) => {
                    setNewApiKeyData({
                      ...newApiKeyData,
                      name: e,
                    });
                  }}
                  placeholder="Enter a name for your key"
                />
                {allScopes.length > 0 && (
                  <div className="pt-4">
                    <div className="text-sm font-medium pb-4">Key scopes</div>

                    <Table>
                      <TableHeader>
                        <TableColumn>Scopes</TableColumn>
                        <TableColumn className="flex justify-end items-center">
                          Permissions
                        </TableColumn>
                      </TableHeader>
                      <TableBody>
                        {allScopes.map((keyScope) => {
                          let currentPermission: "none" | "read" | "write" =
                            "none";
                          const foundScope = newApiKeyData.scopes.find(
                            (scope) =>
                              scope.representation === keyScope.representation
                          );
                          if (foundScope) {
                            if (foundScope.read) {
                              currentPermission = "read";
                            }
                            if (
                              foundScope.create &&
                              foundScope.update &&
                              foundScope.del
                            ) {
                              currentPermission = "write";
                            }
                          } else {
                            currentPermission = "none";
                          }

                          return (
                            <TableRow>
                              <TableCell>
                                <div className="flex items-center gap-2">
                                  <span>{keyScope.name}</span>
                                  {keyScope.description && (
                                    <Tooltip
                                      placement="top"
                                      content={keyScope.description}
                                      className="max-w-40 text-xs"
                                    >
                                      <Info size={14} />
                                    </Tooltip>
                                  )}
                                </div>
                              </TableCell>
                              <TableCell>
                                <div
                                  className={cn(
                                    props.darkMode
                                      ? "text-slate-100"
                                      : "text-slate-500",
                                    "flex text-xs gap-3 justify-end items-center font-medium "
                                  )}
                                >
                                  <span
                                    className={cn(
                                      props.darkMode
                                        ? "hover:bg-slate-600"
                                        : "hover:bg-slate-100",
                                      "text-xs cursor-pointer p-1 rounded-md"
                                    )}
                                    style={
                                      currentPermission === "none"
                                        ? {
                                            backgroundColor: accentColor,
                                            color: textColor,
                                          }
                                        : {}
                                    }
                                    onClick={() => {
                                      const newScopes = changeScopePermission(
                                        newApiKeyData.scopes,
                                        keyScope.representation,
                                        "none"
                                      );

                                      setNewApiKeyData({
                                        ...newApiKeyData,
                                        scopes: newScopes,
                                      });
                                    }}
                                  >
                                    None
                                  </span>
                                  <span
                                    className={cn(
                                      props.darkMode
                                        ? "hover:bg-slate-600"
                                        : "hover:bg-slate-100",
                                      "text-xs cursor-pointer p-1 rounded-md"
                                    )}
                                    style={
                                      currentPermission === "read"
                                        ? {
                                            backgroundColor: accentColor,
                                            color: textColor,
                                          }
                                        : {}
                                    }
                                    onClick={() => {
                                      const newScopes = changeScopePermission(
                                        newApiKeyData.scopes,
                                        keyScope.representation,
                                        "read"
                                      );

                                      setNewApiKeyData({
                                        ...newApiKeyData,
                                        scopes: newScopes,
                                      });
                                    }}
                                  >
                                    Read
                                  </span>
                                  <span
                                    className={cn(
                                      props.darkMode
                                        ? "hover:bg-slate-600"
                                        : "hover:bg-slate-100",
                                      "text-xs cursor-pointer p-1 rounded-md"
                                    )}
                                    style={
                                      currentPermission === "write"
                                        ? {
                                            backgroundColor: accentColor,
                                            color: textColor,
                                          }
                                        : {}
                                    }
                                    onClick={() => {
                                      const newScopes = changeScopePermission(
                                        newApiKeyData.scopes,
                                        keyScope.representation,
                                        "write"
                                      );

                                      setNewApiKeyData({
                                        ...newApiKeyData,
                                        scopes: newScopes,
                                      });
                                    }}
                                  >
                                    Write
                                  </span>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  size="sm"
                  className="rounded-md bg-none font-medium"
                  onPress={onClose}
                >
                  Close
                </Button>
                <Button
                  size="sm"
                  className="rounded-md font-medium"
                  style={{
                    backgroundColor: accentColor,
                    color: textColor,
                  }}
                  onPress={() => {
                    handleCreateApiKey();
                    onClose();
                    setShowNewKeyDetailsModal(true);
                  }}
                >
                  Create
                </Button>
              </ModalFooter>
            </div>
          )}
        </ModalContent>
      </Modal>

      <Modal
        isOpen={showEditKeyModal}
        onOpenChange={(isOpen) => {
          setShowEditKeyModal(isOpen);
        }}
        size="lg"
        className={cn(props.darkMode ? "dark bg-zinc-900 text-white" : "")}
      >
        <ModalContent className=" max-h-full overflow-scroll">
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Edit your key
              </ModalHeader>
              <ModalBody>
                <span className="text-sm font-medium">Key name</span>
                <Input
                  size="sm"
                  className="w-full rounded-sm"
                  value={apiKeyToEdit?.name}
                  onValueChange={(e) => {
                    apiKeyToEdit &&
                      setApiKeyToEdit({
                        ...apiKeyToEdit,
                        name: e,
                      });
                  }}
                />
                <div className="pt-4">
                  <div className="text-sm font-medium pb-4">Key scopes</div>

                  <Table>
                    <TableHeader>
                      <TableColumn>Scopes</TableColumn>
                      <TableColumn className="flex justify-end items-center">
                        Permissions
                      </TableColumn>
                    </TableHeader>
                    <TableBody>
                      {allScopes.map((keyScope) => {
                        let currentPermission: "none" | "read" | "write" =
                          "none";
                        const foundScope = apiKeyToEdit?.scopes?.find(
                          (scope) =>
                            scope.representation === keyScope.representation
                        );
                        if (foundScope) {
                          if (foundScope.read) {
                            currentPermission = "read";
                          }
                          if (
                            foundScope.create &&
                            foundScope.update &&
                            foundScope.del
                          ) {
                            currentPermission = "write";
                          }
                        } else {
                          currentPermission = "none";
                        }

                        return (
                          <TableRow>
                            <TableCell>
                              <div className="flex items-center gap-2">
                                <span>{keyScope.name}</span>
                                {keyScope.description && (
                                  <Tooltip
                                    placement="top"
                                    content={keyScope.description}
                                    className="max-w-40 text-xs"
                                  >
                                    <Info size={14} />
                                  </Tooltip>
                                )}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                className={cn(
                                  props.darkMode
                                    ? "text-slate-100"
                                    : "text-slate-500",
                                  "flex text-xs gap-3 justify-end items-center font-medium "
                                )}
                              >
                                <span
                                  className={cn(
                                    props.darkMode
                                      ? "hover:bg-slate-600"
                                      : "hover:bg-slate-100",
                                    "text-xs cursor-pointer p-1 rounded-md"
                                  )}
                                  style={
                                    currentPermission === "none"
                                      ? {
                                          backgroundColor: accentColor,
                                          color: textColor,
                                        }
                                      : {}
                                  }
                                  onClick={() => {
                                    const newScopes = changeScopePermission(
                                      apiKeyToEdit?.scopes ?? [],
                                      keyScope.representation,
                                      "none"
                                    );

                                    apiKeyToEdit &&
                                      setApiKeyToEdit({
                                        ...apiKeyToEdit,
                                        scopes: newScopes,
                                      });
                                  }}
                                >
                                  None
                                </span>
                                <span
                                  className={cn(
                                    props.darkMode
                                      ? "hover:bg-slate-600"
                                      : "hover:bg-slate-100",
                                    "text-xs cursor-pointer p-1 rounded-md"
                                  )}
                                  style={
                                    currentPermission === "read"
                                      ? {
                                          backgroundColor: accentColor,
                                          color: textColor,
                                        }
                                      : {}
                                  }
                                  onClick={() => {
                                    const newScopes = changeScopePermission(
                                      apiKeyToEdit?.scopes ?? [],
                                      keyScope.representation,
                                      "read"
                                    );

                                    apiKeyToEdit &&
                                      setApiKeyToEdit({
                                        ...apiKeyToEdit,
                                        scopes: newScopes,
                                      });
                                  }}
                                >
                                  Read
                                </span>
                                <span
                                  className={cn(
                                    props.darkMode
                                      ? "hover:bg-slate-600"
                                      : "hover:bg-slate-100",
                                    "text-xs cursor-pointer p-1 rounded-md"
                                  )}
                                  style={
                                    currentPermission === "write"
                                      ? {
                                          backgroundColor: accentColor,
                                          color: textColor,
                                        }
                                      : {}
                                  }
                                  onClick={() => {
                                    const newScopes = changeScopePermission(
                                      apiKeyToEdit?.scopes ?? [],
                                      keyScope.representation,
                                      "write"
                                    );

                                    apiKeyToEdit &&
                                      setApiKeyToEdit({
                                        ...apiKeyToEdit,
                                        scopes: newScopes,
                                      });
                                  }}
                                >
                                  Write
                                </span>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  size="sm"
                  className="rounded-md bg-none  font-medium"
                  onPress={onClose}
                >
                  Close
                </Button>
                <Button
                  size="sm"
                  className="rounded-md font-medium"
                  style={{
                    backgroundColor: accentColor,
                    color: textColor,
                  }}
                  onPress={() => {
                    handleEditApiKey();
                    onClose();
                  }}
                >
                  Update Key
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        isOpen={showNewKeyDetailsModal}
        onOpenChange={(isOpen) => {
          setShowNewKeyDetailsModal(isOpen);
        }}
        isDismissable={false}
        onClose={() => {
          refreshApiKeys();
        }}
        className={cn(props.darkMode ? "dark bg-zinc-900 text-white" : "")}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Your new API key
              </ModalHeader>
              <ModalBody>
                <div className="text-sm">
                  <div className="font-semibold pb-3">Keep your key safe</div>
                  <div
                    className={cn(
                      props.darkMode ? "text-slate-300" : "text-slate-600",
                      "pb-5"
                    )}
                  >
                    Save and store this new key to a secure place, such as a
                    password manager or secret store. You won’t be able to see
                    it again.
                  </div>

                  <Tooltip placement="top" content={"Copy to clipboard"}>
                    <pre
                      onClick={() => {
                        navigator.clipboard.writeText(
                          newlyCreatedApiKey?.apiKey.key ?? ""
                        );
                        toast("Copied to clipboard", {
                          duration: 2000,
                        });
                      }}
                      className="mb-6 text-xs whitespace-pre-wrap break-all max-w-[350px] p-3 border-1  cursor-pointer select-none"
                    >
                      {newlyCreatedApiKey?.apiKey.key}
                    </pre>
                  </Tooltip>
                  <Divider />
                  <div className="pt-4">
                    <div className="font-semibold pb-3">Add note</div>
                    <Textarea
                      className="max-w-[350px]  text-xs"
                      value={keyNotesInputValue}
                      onValueChange={(e) => {
                        setKeyNotesInputValue(e);
                      }}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  size="sm"
                  className="rounded-sm bg-none border-1"
                  onPress={() => {
                    onClose();
                  }}
                >
                  Done
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        isOpen={showRotateKeyModal}
        onOpenChange={(isOpen) => {
          setShowRotateKeyModal(isOpen);
        }}
        className={cn(props.darkMode ? "dark bg-zinc-900 text-white" : "")}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Rotate Key
              </ModalHeader>
              <ModalBody>
                <div className="text-sm">
                  <div className="font-medium pb-3">
                    Are you sure you want to rotate{" "}
                    <pre
                      className={cn(
                        props.darkMode ? "bg-slate-600" : "",
                        " text-xs px-2 py-1  inline-block"
                      )}
                    >
                      {apiKeyToRotate?.name}
                    </pre>{" "}
                    key
                  </div>
                  <div
                    className={cn(
                      props.darkMode ? "text-slate-300" : "text-slate-600",
                      "pb-5"
                    )}
                  >
                    This action will invalidate the current key and generate a
                    new one.
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  size="sm"
                  className="rounded-md bg-none font-medium"
                  onPress={onClose}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  className="rounded-md bg-none "
                  style={{
                    backgroundColor: accentColor,
                    color: textColor,
                  }}
                  onPress={() => {
                    handleApiKeyRotation();
                    onClose();
                  }}
                >
                  Rotate Key
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        isOpen={showDeleteKeyModal}
        onOpenChange={(isOpen) => {
          setShowDeleteKeyModal(isOpen);
        }}
        className={cn(props.darkMode ? "dark bg-zinc-900 text-white" : "")}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Delete Key
              </ModalHeader>
              <ModalBody>
                <div className="text-sm">
                  <div className="font-medium pb-3">
                    Are you sure you want to delete{" "}
                    <pre
                      className={cn(
                        props.darkMode ? "bg-slate-600" : "",
                        " text-xs px-2 py-1  inline-block"
                      )}
                    >
                      {apiKeyToDelete?.name}
                    </pre>{" "}
                    key
                  </div>
                  <div
                    className={cn(
                      props.darkMode ? "text-slate-300" : "text-slate-600",
                      "pb-5"
                    )}
                  >
                    This action is irreversible. You will not be able to recover
                    this key.
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  size="sm"
                  className="rounded-md bg-none font-medium"
                  onPress={onClose}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  className="rounded-md bg-none "
                  style={{
                    backgroundColor: accentColor,
                    color: textColor,
                  }}
                  onPress={() => {
                    handleDeleteApiKey();
                    onClose();
                  }}
                >
                  Delete Key
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ApiKeyManager;
