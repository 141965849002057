import { ApiCalls } from "@/lib/ApiCall";
import {
  cn,
  dateFns,
  formatDate,
  getDatesForTinyBird,
  utcToYYYYMMDD,
} from "@/lib/utils";
import { BarChart } from "@tremor/react";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Progress,
  Spinner,
  Switch,
} from "@nextui-org/react";
import GlobalAtoms from "@/lib/GlobalStore";
import Stripe from "stripe";

const UsageSettingsManager = () => {
  const teamData = useRecoilState(GlobalAtoms.TeamAtom)[0];
  const currentTheme = useRecoilState(GlobalAtoms.DashboardThemeAtom)[0];
  const [waitingForStripeSessionLink, setWaitingForStripeSessionLink] =
    useState(false);
  const [selectedUpgradePeriod, setSelectedUpgradePeriod] = useState<
    "monthly" | "yearly"
  >("yearly");

  const [billingUsage, setBillingUsage] = useState<
    Stripe.Billing.MeterEventSummary[]
  >([]);

  useEffect(() => {
    const getData = async () => {
      if (!teamData?.id) return;
      // await ApiCalls.getBillingData(teamData.id);
    };

    getData();
  }, [teamData?.id]);

  useEffect(() => {
    const getData = async () => {
      await ApiCalls.getTeamDetails();
      const usageData = await ApiCalls.getBillingUsage();
      setBillingUsage(usageData);
    };

    getData();
  }, []);

  const dateStrings = getDatesForTinyBird({
    timeGap: 24 * 60 * 60 * 1000,
    arrayLength: 31,
  });

  const handleUpgradePlanClick = async () => {
    if (waitingForStripeSessionLink) return;
    setWaitingForStripeSessionLink(true);
    const sessionUrl = await ApiCalls.createStripeCheckoutSession({
      pricingType: selectedUpgradePeriod === "monthly" ? "monthly" : "yearly",
    });
    setWaitingForStripeSessionLink(false);
    window.open(sessionUrl, "_blank");
  };

  const handleBillingCenterClick = async () => {
    if (waitingForStripeSessionLink) return;
    setWaitingForStripeSessionLink(true);
    const sessionUrl = await ApiCalls.createStripeBillingPortalSession();
    setWaitingForStripeSessionLink(false);
    window.open(sessionUrl, "_blank");
  };

  const expiryDate =
    teamData?.plan === "FREE"
      ? teamData?.freePlanEnd
      : teamData?.planExpirationDate;

  const creditsUsed = billingUsage.reduce(
    (acc, curr) => acc + curr.aggregated_value,
    0
  );

  const creditLimit = teamData?.plan === "FREE" ? 2000 : 100000;

  const creditsRemaining = creditLimit - creditsUsed;

  return (
    <div>
      <div className="flex flex-wrap gap-20 ">
        <Card
          className={cn(
            teamData?.plan === "FREE" &&
              "bg-gradient-to-tr from-white to-[#dcf9bd] dark:from-zinc-900 dark:to-[#344b30]",
            teamData?.plan === "PRO" &&
              "bg-gradient-to-tr from-white to-[#c2f2ff] dark:from-zinc-900 dark:to-[#516f78]",
            teamData?.plan === "ENTERPRISE" &&
              "bg-gradient-to-tr from-white to-[#f9e7ff] dark:from-zinc-900 dark:to-[#3f2d3f]",
            "py-4 px-4 w-[400px]  bg-gradient-to-tr "
          )}
        >
          <CardHeader className="pb-0 pt-2 flex-col items-start">
            <p className="text-xs uppercase font-bold">Your Current Plan</p>
          </CardHeader>
          <CardBody className="overflow-visible py-2">
            <h4 className="font-bold text-large">
              {teamData?.plan === "FREE"
                ? "Free"
                : teamData?.plan === "PRO"
                ? "Pro"
                : teamData?.plan === "ENTERPRISE"
                ? "Enterprise"
                : ""}{" "}
              Plan
            </h4>
            {expiryDate && !isNaN(Number(expiryDate)) && (
              <small className="text-default-500">
                Renews on {formatDate(Number(expiryDate) * 1000)}
              </small>
            )}
            {teamData?.plan === "FREE" ? (
              <div className="flex flex-col gap-2 mt-4">
                <div className="flex text-xs font-medium items-center justify-end">
                  <span className="pr-2">Monthly</span>{" "}
                  <Switch
                    defaultSelected
                    size="sm"
                    color="default"
                    isSelected={selectedUpgradePeriod === "yearly"}
                    onValueChange={(val) => {
                      setSelectedUpgradePeriod(val ? "yearly" : "monthly");
                    }}
                  />
                  Yearly
                </div>
                <Button
                  size="sm"
                  color="primary"
                  onClick={handleUpgradePlanClick}
                  isDisabled={waitingForStripeSessionLink}
                >
                  {waitingForStripeSessionLink ? (
                    <Spinner color="white" size="sm" />
                  ) : (
                    "Upgrade Plan"
                  )}
                </Button>
              </div>
            ) : teamData?.plan === "PRO" ? (
              <div className="flex flex-col gap-2 mt-4">
                <a
                  className="font-medium text-xs hover:underline"
                  href="mailto:support@oneloop.ai?subject=Cancel%20Subscription"
                >
                  Downgrade
                </a>
                <Button
                  isDisabled={waitingForStripeSessionLink}
                  onClick={handleBillingCenterClick}
                  size="sm"
                  color="primary"
                >
                  {waitingForStripeSessionLink ? (
                    <Spinner color="white" size="sm" />
                  ) : (
                    "Billing Center"
                  )}
                </Button>
              </div>
            ) : (
              <div className="flex flex-col gap-2 mt-4"></div>
            )}
          </CardBody>
        </Card>

        <Card className="p-4 w-[400px] ">
          <CardHeader>
            <div className="text-lg font-medium">Billing Usage</div>
          </CardHeader>
          <CardBody className="flex flex-col justify-between">
            <div>
              <div className="text-xs font-medium">Credits Used</div>
              <div className="text-lg font-bold">{creditsUsed}</div>
            </div>
            <Progress
              aria-label="Loading..."
              value={creditsUsed}
              maxValue={creditLimit}
              className="max-w-md"
              label={`${creditsRemaining.toLocaleString()} credits remaining`}
              classNames={{
                labelWrapper: "flex justify-end",
                label: "text-xs font-medium",
              }}
            />
          </CardBody>
        </Card>
      </div>
      <Card className="mt-20 p-4 max-w-[1000px]">
        <CardHeader className="flex flex-col items-start">
          <div className="text-lg font-medium">Daily Usage</div>
          <div className="text-xs font-medium text-default-400">
            Last 30 days
          </div>
        </CardHeader>
        <CardBody>
          <BarChart
            data={dateStrings.map((date) => {
              const dateTimeInSecs = date.getTime() / 1000;
              const foundData = billingUsage.find(
                (i) =>
                  i.start_time <= dateTimeInSecs && i.end_time > dateTimeInSecs
              );
              if (foundData) {
                console.log(foundData);
              }
              return {
                date: dateFns.format(date, "do MMM yyyy"),
                "Verification Requests": foundData?.aggregated_value ?? 0,
              };
            })}
            index="date"
            categories={["Verification Requests"]}
            colors={currentTheme === "dark" ? ["lime"] : ["black"]}
            yAxisWidth={48}
            onValueChange={(v) => console.log(v)}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default UsageSettingsManager;
