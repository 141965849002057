import {
  RetrieveWorkspaceResponseType,
  TinyBirdJWTForWorkspaceResponseType,
  UpdateWorkspaceRequestType,
  UpdateWorkspaceResponseType,
} from "@/types/workspace";
import { PandaClient } from "./PandaClient";
import {
  CreateNewUserRequestType,
  RetrieveTeamResponseType,
  RetrieveUserResponseType,
  TinyBirdJWTForBillingResponseType,
} from "@/types/user";
import { getRecoil, setRecoil } from "recoil-nexus";
import GlobalAtoms, {
  TinyBirdAnalyticsDataType,
  TinyBirdBillingDataType,
} from "./GlobalStore";
import { WorkspaceType } from "@/types/schema";
import {
  CreateWorkspaceScopeRequestType,
  RetrieveAllWorkspaceScopesResponseType,
} from "@/types/scope";
import {
  CreateApiKeyRequestType,
  CreateApiKeyResponseType,
  DeleteApiKeyResponseType,
  EditApiKeyRequestType,
  EditApiKeyResponseType,
  RetrieveApiKeysResponseType,
  RotateApiKeyResponseType,
} from "@/types/api-key";
import {
  CreateSDKKeyRequestType,
  CreateSDKKeyResponseType,
  RetrieveSDKKeyScopeResponseType,
  RetrieveSDKKeysResponseType,
  RevokeSDKKeyResponseType,
} from "@/types/sdk-key";
import {
  CreateStripeCustomerRequestType,
  CreateStripeCustomerResponseType,
  CreateStripePortalSessionResponseType,
} from "@/types/billing";
import { Stripe } from "stripe";

export class ApiCalls {
  static initialize = async () => {
    await ApiCalls.getUserDetails();
    await ApiCalls.getTeamDetails();
    await ApiCalls.getAllWorkspaces();
    const allWorkspaces = getRecoil(GlobalAtoms.WorkspacesAtom);
    if (allWorkspaces.length === 0) {
      setRecoil(GlobalAtoms.UserOnboardedAtom, false);
      return;
    }
  };

  static getUserDetails = async () => {
    let errorFetching = false;
    try {
      const response = await PandaClient.get("/v1/user");
      const data = response.data as RetrieveUserResponseType;
      setRecoil(GlobalAtoms.UserAtom, data.user);
      return data;
    } catch (e) {
      errorFetching = true;
    }

    if (errorFetching) {
      const reqBody: CreateNewUserRequestType = {
        role: "ADMIN",
      };
      await PandaClient.post("/v1/user", reqBody);
      setTimeout(async () => {
        const response = await PandaClient.get("/v1/user");
        const data = response.data as RetrieveUserResponseType;
        setRecoil(GlobalAtoms.UserAtom, data.user);
        return data;
      }, 1000);
    }
  };

  static getTeamDetails = async () => {
    const response = await PandaClient.get(`/v1/team/`);
    const data = response.data as RetrieveTeamResponseType;
    setRecoil(GlobalAtoms.TeamAtom, data.team);
    return data;
  };

  static getAllWorkspaces = async () => {
    const response = await PandaClient.get("/v1/workspace");
    const data = response.data as RetrieveWorkspaceResponseType;
    setRecoil(GlobalAtoms.WorkspacesAtom, data.workspaces);
    const selectedWorkspace = getRecoil(GlobalAtoms.SelectedWorkspaceAtom);
    if (selectedWorkspace === null && data.workspaces.length > 0) {
      setRecoil(GlobalAtoms.SelectedWorkspaceAtom, data.workspaces[0]);
    }
  };

  static createNewWorkspace = async (name: string) => {
    const reqBody = {
      name,
    };
    const response = await PandaClient.post("/v1/workspace", reqBody);
    const data = response.data as { workspace: WorkspaceType };
    await ApiCalls.getAllWorkspaces();
    setRecoil(GlobalAtoms.SelectedWorkspaceAtom, data.workspace);
    return data.workspace;
  };

  static getAllScopes = async (workspaceId: string) => {
    const response = await PandaClient.get(
      `/v1/workspace/${workspaceId}/scopes`
    );
    const data = response.data as RetrieveAllWorkspaceScopesResponseType;
    setRecoil(GlobalAtoms.AllScopesAtom, data.scopes);
  };

  static createWorkspaceScope = async (
    workspaceId: string,
    scope: CreateWorkspaceScopeRequestType
  ) => {
    await PandaClient.post(`/v1/workspace/${workspaceId}/scope`, scope);
    await ApiCalls.getAllScopes(workspaceId);
  };

  static updateWorkspace = async (
    workspaceId: string,
    reqBody: UpdateWorkspaceRequestType
  ) => {
    const response = await PandaClient.patch(
      `/v1/workspace/${workspaceId}`,
      reqBody
    );
    const data = response.data as UpdateWorkspaceResponseType;
    setRecoil(GlobalAtoms.SelectedWorkspaceAtom, data.workspace);
    setRecoil(GlobalAtoms.WorkspacesAtom, (prev) => {
      return prev.map((workspace) => {
        if (workspace.id === workspaceId) {
          return data.workspace;
        }
        return workspace;
      });
    });
  };

  static getAllApiKeys = async (workspaceId: string) => {
    const response = await PandaClient.get(
      `/v1/workspace/${workspaceId}/api-key`
    );

    const data = response.data as RetrieveApiKeysResponseType;
    setRecoil(GlobalAtoms.AllApiKeysAtom, data.apiKeys);
    return response.data;
  };

  static createApiKey = async (reqBody: CreateApiKeyRequestType) => {
    const response = await PandaClient.post(`/v1/api-key`, reqBody);
    const data = response.data as CreateApiKeyResponseType;
    return data;
  };

  static rotateApiKey = async (workspaceId: string, apiKeyId: string) => {
    const response = await PandaClient.post(
      `/v1/workspace/${workspaceId}/api-key/${apiKeyId}/rotate`
    );
    const data = response.data as RotateApiKeyResponseType;
    return data;
  };

  static deleteApiKey = async (workspaceId: string, apiKeyId: string) => {
    const response = await PandaClient.delete(
      `/v1/workspace/${workspaceId}/api-key/${apiKeyId}`
    );
    const data = response.data as DeleteApiKeyResponseType;
    return data;
  };

  static editApiKey = async (
    workspaceId: string,
    apiKeyId: string,
    reqBody: EditApiKeyRequestType
  ) => {
    const response = await PandaClient.patch(
      `/v1/workspace/${workspaceId}/api-key/${apiKeyId}`,
      reqBody
    );
    const data = response.data as EditApiKeyResponseType;
    return data;
  };

  static getAllSDKScopes = async () => {
    const response = await PandaClient.get(`/v1/sdk/scopes`);
    const data = response.data as RetrieveSDKKeyScopeResponseType;
    setRecoil(GlobalAtoms.AllSDKScopesAtom, data.scopes);
    return data.scopes;
  };

  static createSDKKey = async (reqBody: CreateSDKKeyRequestType) => {
    const response = await PandaClient.post(`/v1/sdk/api-key`, reqBody);
    const data = response.data as CreateSDKKeyResponseType;
    return data;
  };

  static getAllSDKKeys = async () => {
    const response = await PandaClient.get(`/v1/sdk/api-key`);
    const data = response.data as RetrieveSDKKeysResponseType;
    setRecoil(GlobalAtoms.AllSDKKeysAtom, data.sdkKeys);
    return data;
  };

  static rotateSDKKey = async (apiKeyId: string) => {
    const response = await PandaClient.post(
      `/v1/sdk/api-key/${apiKeyId}/rotate`
    );
    const data = response.data as RotateApiKeyResponseType;
    return data;
  };

  static deleteSDKKey = async (apiKeyId: string) => {
    const response = await PandaClient.delete(`/v1/sdk/api-key/${apiKeyId}`);
    const data = response.data as RevokeSDKKeyResponseType;
    return data;
  };

  static editSDKKey = async (
    apiKeyId: string,
    reqBody: EditApiKeyRequestType
  ) => {
    const response = await PandaClient.patch(
      `/v1/sdk/api-key/${apiKeyId}`,
      reqBody
    );
    const data = response.data as EditApiKeyResponseType;
    return data;
  };

  static getAnalyticsJwt = async (workspaceId: string) => {
    const response = await PandaClient.get(
      `/v1/workspace/${workspaceId}/analytics`
    );
    const data = response.data as TinyBirdJWTForWorkspaceResponseType;
    setRecoil(GlobalAtoms.TinyBirdAnalyticsJwtAtom, data.token);
    return data;
  };

  static getAnalyticsData = async (
    workspaceId: string,
    forceReload?: boolean
  ) => {
    let tinyBirdAnalyticsJwt = getRecoil(GlobalAtoms.TinyBirdAnalyticsJwtAtom);

    if (!tinyBirdAnalyticsJwt || tinyBirdAnalyticsJwt === null) {
      tinyBirdAnalyticsJwt = (await this.getAnalyticsJwt(workspaceId)).token;
    }
    const parsedJwt = JSON.parse(atob(tinyBirdAnalyticsJwt.split(".")[1]));
    let analyticsData = getRecoil(GlobalAtoms.AnalyticsDataAtom);
    const workspaceChanged = analyticsData?.workspaceId !== workspaceId;

    if (parsedJwt.exp < Math.floor(Date.now() / 1000) || workspaceChanged) {
      tinyBirdAnalyticsJwt = (await this.getAnalyticsJwt(workspaceId)).token;
    }

    if (analyticsData && !forceReload && !workspaceChanged) {
      return analyticsData;
    }

    const response = await fetch(
      `https://api.us-west-2.aws.tinybird.co/v0/pipes/tb_daily_verification_stats_mv_pipe.json?wid=${workspaceId}&token=${tinyBirdAnalyticsJwt}`
    );

    const data = (await response.json()) as TinyBirdAnalyticsDataType;
    setRecoil(GlobalAtoms.AnalyticsDataAtom, { ...data, workspaceId });
    return data;
  };

  // static getBillingJwt = async () => {
  //   const response = await PandaClient.get(`/v1/user/billing`);
  //   const data = response.data as TinyBirdJWTForBillingResponseType;
  //   return data;
  // };

  // static getBillingData = async (teamId: string) => {
  //   let tinyBirdBillingJwt = getRecoil(GlobalAtoms.TinyBirdBillingJwtAtom);

  //   if (!tinyBirdBillingJwt || tinyBirdBillingJwt === null) {
  //     tinyBirdBillingJwt = (await this.getBillingJwt()).token;
  //   }
  //   const parsedJwt = JSON.parse(atob(tinyBirdBillingJwt.split(".")[1]));

  //   if (parsedJwt.exp < Math.floor(Date.now() / 1000)) {
  //     tinyBirdBillingJwt = (await this.getBillingJwt()).token;
  //   }

  //   const response = await fetch(
  //     `https://api.us-west-2.aws.tinybird.co/v0/pipes/billing_mv.json?tid=${teamId}&token=${tinyBirdBillingJwt}`
  //   );

  //   const data = (await response.json()) as TinyBirdBillingDataType;
  //   setRecoil(GlobalAtoms.UsageDataAtom, data);
  //   return data;
  // };

  static createStripeCheckoutSession = async (
    reqBody: CreateStripeCustomerRequestType
  ) => {
    const response = await PandaClient.post(
      `/v1/billing/stripe/session`,
      reqBody
    );
    const data = response.data as CreateStripeCustomerResponseType;
    return data.sessionUrl;
  };

  static createStripeBillingPortalSession = async () => {
    const response = await PandaClient.get(`/v1/billing/stripe/portal`);
    const data = response.data as CreateStripePortalSessionResponseType;
    return data.sessionUrl;
  };

  static getStripeSession = async (sessionId: string) => {
    const response = await PandaClient.get(
      `/v1/billing/stripe/session/${sessionId}`
    );
    return response.data;
  };

  static getBillingUsage = async () => {
    const response = await PandaClient.get(`/v1/billing/usage`);
    const data = response.data as Stripe.Billing.MeterEventSummary[];
    return data;
  };
}
