import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import * as dateFnsClass from "date-fns";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const dateFns = dateFnsClass;

export function formatDate(dateTime: number | string) {
  let dateTimeNum = 0;
  if (typeof dateTime === "string") {
    dateTimeNum = parseInt(dateTime);
  } else {
    dateTimeNum = dateTime;
  }

  if (!dateTime || isNaN(dateTimeNum)) {
    return "Never";
  }

  return dateFns.format(dateTime, "MMM dd, yyyy");
}

export function formatDateTime(dateTime: number | string) {
  let dateTimeNum = 0;
  if (typeof dateTime === "string") {
    dateTimeNum = parseInt(dateTime);
  } else {
    dateTimeNum = dateTime;
  }

  if (!dateTime || isNaN(dateTimeNum)) {
    return "Never";
  }
  return dateFns.format(dateTime, "MMM dd, yyyy '@' hh:mm a");
}

export function getFormattedTime(timeInMs: number) {
  const timeInSecs = timeInMs / 1000;
  const hrs = Math.floor(timeInSecs / 3600);
  const mins = Math.floor((timeInSecs % 3600) / 60);
  const secs = Math.floor(timeInSecs % 60);

  let returnString = "";
  if (hrs > 0) {
    returnString += hrs + "h ";
  }
  if (mins > 0) {
    returnString += mins + "m ";
  }
  if (secs > 0) {
    returnString += secs + "s";
  }
  return returnString;
}

export function getTimeSaved(timeInMs: number) {
  const humanTimeMultiple = 7;
  const timeSaved = timeInMs * humanTimeMultiple;
  return timeSaved;
}

export function getTimeFromNowInWords(dateTime: number | string) {
  let dateTimeNum = 0;
  if (typeof dateTime === "string") {
    dateTimeNum = parseInt(dateTime);
  } else {
    dateTimeNum = dateTime;
  }

  if (!dateTime || isNaN(dateTimeNum)) {
    return "Never";
  }

  return dateFns.formatDistanceToNow(dateTime, { addSuffix: true });
}

export function getDatesForTinyBird(props: {
  timeGap: number;
  arrayLength?: number;
  startFrom?: Date;
}) {
  const timeRightNow = props.startFrom ?? new Date();
  const dateHourStrings = new Array(props.arrayLength ?? 50)
    .fill(0)
    .map((_, idx) => {
      const date = new Date();
      date.setDate(timeRightNow.getDate());
      date.setHours(timeRightNow.getHours());
      date.setTime(date.getTime() - idx * props.timeGap);
      return date;
    })
    .reverse();

  return dateHourStrings;
}

export function utcToYYYYMMDD(date: Date) {
  const utcYear = date.getUTCFullYear();
  const utcMonth = date.getUTCMonth() + 1;
  const utcDate = date.getUTCDate();
  return `${utcYear}-${utcMonth < 10 ? "0" + utcMonth : utcMonth}-${
    utcDate < 10 ? "0" + utcDate : utcDate
  }`;
}
